import Wrapper from "../Wrapper";
import Content from "../Content";

function App() {
  return (
    <Wrapper>
      <Content />
    </Wrapper>
  );
}

export default App;
