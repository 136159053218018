import Wrapper from "./styles";

function Social() {
  return (
    <Wrapper>
      <a href="https://twitter.com/Raleigh_CA"  target="_blank" without="true" rel="noreferrer">Twitter</a>
      <a href="https://linkedin.com/in/kenudiggitt/"  target="_blank" without="true" rel="noreferrer">LinkedIn</a>
      <a href="https://github.com/KennethAshley"  target="_blank" without="true" rel="noreferrer">Github</a>
      <a href="https://t.me/raleigh_ca"  target="_blank" without="true" rel="noreferrer">Telegram</a>

      <a href="mailto:ken.u.diggitt@gmail.com">Email</a>
    </Wrapper>
  )
}

export default Social;
