import { styled } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
  padding: 48px 20px;
`;

export default Wrapper;
