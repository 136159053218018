import Social from "../Social";

function Content() {
  return (
      <div>
        <p>Hey, I'm Ken. I dabble in <a href="https://github.com/KennethAshley" target="_blank" without="true" rel="noreferrer">development</a>, design, and <a href="https://www.dropbox.com/scl/fi/7wbp41n8pfmbbkklkii4m/CodeSearch.mov?rlkey=r4gtcg0gxcukj78cu6mfd6feh&dl=0" target="_blank" without="true" rel="noreferrer">videography</a>. Currently, I'm working at <a href="https://www.taoshi.io" target="_blank" without="true" rel="noreferrer">Taoshi</a> where we're leading the charge in the <a href="https://bittensor.com" target="_blank" without="true" rel="noreferrer">Bittensor</a> revolution. I also co-created <a href="https://twitter.com/ReservoirFi" target="_blank" without="true" rel="noreferrer">Reservoir Finance</a>, a Web3 decentralized exchange where you can easily swap cryptocurrencies. I spent the better part of ten years crafting web apps, contributing to open source stuff, winning at hackathons, and doing a bunch of other cool things.</p>
        <p>I'm Kenough.</p>
        <Social />
      </div>
  )
}

export default Content;
