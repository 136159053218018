import { styled } from "styled-components";

const Wrapper = styled.div`
  display: flex;

  a {
    margin-right: 12px;
  }
`;

export default Wrapper;
