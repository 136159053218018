import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	@font-face {
		font-family: 'VulfMono':
		src: local('VulfMono'),
			url('./fonts/Vulf_Mono-Regular_web.woff') format('woff'),
			url('./fonts/Vulf_Mono-Regular_web.woff2') format('woff2');
		font-weight: normal;	
	}
	
	* {
		box-sizing: border-box;
	}
	
	body, html, #root {
		height: 100%;
	}
	
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
		margin: 0;
		padding: 0;
		
		font-family: VulfMono, monospace;
		font-size: 14px;
		line-height: 28px;
		color: #282828;
	}
`;

export default GlobalStyle;
